import { ProductListItem } from '@/hooks/main/Main.hooks';
import { formatCurrency } from '@/utils/number';
import Link from 'next/link';
import { useEffect, useState } from 'react';
import SwiperCore from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import FloatingBadgeList from './FloatingBadgeList';

import 'swiper/css';
import styles from '../../../styles/main/popular-top-10.module.scss';

const optionList = ['#가장 많이 구매했어요', '#가장 후기가 많아요'];

type Props = {
  topReserves: ProductListItem[];
  topReviews: ProductListItem[];
};

export default function PopularTop10({ topReserves, topReviews }: Props) {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const updateSelectedIndex = (index: number) => {
    setSelectedIndex(index);
  };
  const [itemList, setItemList] = useState<ProductListItem[]>([]);
  const [slideList, setSlideList] = useState<ProductListItem[][]>([]);
  const [swiper, setSwiper] = useState<SwiperCore>();

  useEffect(() => {
    if (selectedIndex === 0) setItemList(topReserves);
    else setItemList(topReviews);
  }, [selectedIndex, topReserves, topReviews]);

  useEffect(() => {
    if (itemList.length === 0) {
      setSlideList([]);
      return;
    }
    if (itemList.length === 1) {
      setSlideList([[itemList[0]]]);
      return;
    }
    const size = Math.floor(itemList.length / 2);
    const hasRemain = itemList.length % 2 !== 0;
    const newSlideList = [];
    for (let i = 0; i < size; i++) {
      newSlideList.push(itemList.slice(i * 2, i * 2 + 2));
    }

    if (hasRemain) {
      newSlideList.push([itemList[itemList.length - 1]]);
    }
    setSlideList(newSlideList);
  }, [itemList]);

  useEffect(() => {
    if (swiper) {
      swiper.slideTo(0, 0);
    }
  }, [slideList, swiper]);

  return (
    <div className={styles.root}>
      <div className={styles.title}>인기 TOP 10</div>
      <FloatingBadgeList
        className={styles.floatingBadges}
        list={optionList.map((item) => ({ label: item, value: item }))}
        setSelectedIndex={updateSelectedIndex}
      />
      <Swiper
        className={styles.swiper}
        onSwiper={setSwiper}
        slidesPerView={1.15}
        onSlideChange={(swiper) => {
          if (swiper.activeIndex === slideList.length) {
            swiper.slideTo(slideList.length - 1, 0);
          }
        }}>
        {slideList.map((slideItemSet, slideIndex) => (
          <SwiperSlide key={`top-${selectedIndex === 0 ? 'buy' : 'rev'}-${slideIndex}`}>
            <div className={styles.slideContainer}>
              {slideItemSet.map((topItem, index) => {
                const isAcco = topItem.productType === '숙소예약';
                return (
                  <Link
                    key={`topItem-${selectedIndex === 0 ? 'buy' : 'rev'}-${topItem.id}`}
                    href={{
                      pathname: `/product/${topItem.id}`,
                      query: {
                        categoryId: topItem.categoryId,
                        villageName: topItem.villageName,
                      },
                    }}>
                    <div className={styles.itemCard}>
                      <div className={styles.itemImgWrapper}>
                        <img src={topItem.thumbnailUrl} />
                        <div className={styles.itemNumber}>{slideIndex * 2 + (index + 1)}</div>
                      </div>
                      <div className={styles.itemContent}>
                        <div className={styles.itemSubTitle}>
                          {topItem.categoryName} • {topItem.location}
                        </div>
                        <div className={styles.itemMainTitle}>{topItem.name}</div>
                        <div className={styles.itemPriceWrapper}>
                          <div className={styles.currentPrice}>
                            {formatCurrency(topItem.price)}원{isAcco && '~'}
                          </div>
                          {!isAcco && topItem.originPrice > 0 && (
                            <div className={styles.originPrice}>
                              {formatCurrency(topItem.originPrice)}원
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </Link>
                );
              })}
            </div>
          </SwiperSlide>
        ))}
        <SwiperSlide />
      </Swiper>
    </div>
  );
}
