import { RandomItem } from '@/hooks/main/ThisWeekRecommandation.hooks';
import { formatCurrency } from '@/utils/number';
import Link from 'next/link';
import styles from '../../../styles/main/this-week-recommandation.module.scss';

type Props = {
  randomItems: RandomItem[];
};

export default function ThisWeekRecommandation({ randomItems }: Props) {
  return (
    <div className={styles.root}>
      <div className={styles.title}>이번 주말 뭐하지 고민될 때</div>
      <div className={styles.itemGrid}>
        {randomItems.map((randomItem) => {
          const isAcco = randomItem.productType === '숙소예약';
          return (
            <Link
              key={randomItem.id}
              href={{
                pathname: `/product/${randomItem.id}`,
                query: { categoryId: randomItem.categoryId, villageName: randomItem.villageName },
              }}>
              <div className={styles.itemCard}>
                <img className={styles.itemImg} src={randomItem.thumbnailUrl} />
                <div className={styles.itemContent}>
                  <div className={styles.itemSubTitle}>
                    {randomItem.categoryName} • {randomItem.location}
                  </div>
                  <div className={styles.itemMainTitle}>{randomItem.name}</div>
                  <div className={styles.itemPriceWrapper}>
                    <div className={styles.currentPrice}>
                      {formatCurrency(randomItem.price)}원{isAcco && '~'}
                    </div>
                    {!isAcco && randomItem.originPrice > 0 && (
                      <div className={styles.originPrice}>
                        {formatCurrency(randomItem.originPrice)}원
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </Link>
          );
        })}
      </div>
    </div>
  );
}
