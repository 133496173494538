import classNames from 'classnames';
import Link from 'next/link';
import SearchIcon from 'public/assets/images/common/search-icon.svg';
import styles from '../../../styles/main/search-bar.module.scss';

type Props = {
  className?: string;
};

export default function SearchBar({ className }: Props) {
  return (
    <Link className={classNames(styles.root, className)} href="/search">
      <SearchIcon />
      <div className={styles.searchInputWrapper}>
        <input placeholder="무엇을 찾아볼까요?" readOnly={true} />
      </div>
    </Link>
  );
}
