import { Container } from '@/components/container/Container';
import FooterComponent from '@/components/layout/Footer';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import { HTMLAttributes, ReactNode, RefObject, useState } from 'react';
import styles from '../../../styles/layout/global-layout.module.scss';
import mls from '../../../styles/layout/layout-main.module.scss';

export type LayoutProps = {
  className?: string;
  Header?: ReactNode;
  children: ReactNode;
  Footer?: ReactNode;
  onBodyScroll?: () => void;
  bodyRef?: RefObject<HTMLDivElement>;
  disableHeaderShadow?: boolean;
} & HTMLAttributes<HTMLDivElement>;

export const Layout = ({
  className,
  Header,
  children,
  Footer = <FooterComponent />,
  onBodyScroll,
  bodyRef,
  disableHeaderShadow,
  ...props
}: LayoutProps) => {
  const router = useRouter();
  const isReservationPage = router.pathname === '/product/[productId]/reservation';

  const [scrollPosition, setScrollPosition] = useState(0);

  return (
    <div
      className={classNames(styles.root, className, {
        [mls.headerShadow]: !disableHeaderShadow && scrollPosition > 0,
      })}
      {...props}>
      {!!Header && <div className={classNames(styles.header, 'layout-header')}>{Header}</div>}
      <div
        className={styles.body}
        onScroll={(e) => {
          const { scrollTop } = e.currentTarget;

          setScrollPosition(scrollTop);
          onBodyScroll?.();
        }}
        ref={bodyRef}>
        <Container
          className={classNames(styles.content, {
            [styles.reservationContent]: isReservationPage,
          })}>
          {children}
        </Container>
      </div>
      {!!Footer && <div className={classNames(styles.footer, 'layout-footer')}>{Footer}</div>}
    </div>
  );
};
