import { Container } from '@/components/container/Container';
import Link from 'next/link';
import { useRouter } from 'next/router';
import ActiveMyPageIcon from 'public/assets/images/common/active-mypage-icon.svg';
import ActiveStoryIcon from 'public/assets/images/common/active-story-icon.svg';
import AroundMeIcon from 'public/assets/images/common/around-me-icon.svg';
import HomeIcon from 'public/assets/images/common/home-icon.svg';
import MyFavoriteIcon from 'public/assets/images/common/my-favorite-icon.svg';
import MyPageIcon from 'public/assets/images/common/mypage-icon.svg';
import StoryIcon from 'public/assets/images/common/story-icon.svg';
import styles from '../../../styles/layout/footer.module.scss';

// ${styles.active}
export default function Footer() {
  const router = useRouter();

  return (
    <div className={styles.root}>
      <Container className={styles.container}>
        <Link href="/around-me">
          <div
            className={`${styles.menu} ${router.pathname === '/around-me' ? styles.active : ''}`}>
            <AroundMeIcon />
            <div className={styles.title}>내주변</div>
          </div>
        </Link>
        <Link href="/favorite">
          <div className={`${styles.menu} ${router.pathname === '/favorite' ? styles.active : ''}`}>
            <MyFavoriteIcon />
            <div className={styles.title}>찜</div>
          </div>
        </Link>
        <Link href="/">
          <div className={`${styles.menu} ${router.pathname === '/' ? styles.active : ''}`}>
            <HomeIcon />
            <div className={styles.title}>홈</div>
          </div>
        </Link>
        <Link href="/story">
          <div
            className={`${styles.menu} ${router.pathname === '/story' ? styles.active : ''} ${styles.story}`}>
            {router.pathname === '/story' ? <ActiveStoryIcon /> : <StoryIcon />}
            <div className={styles.title}>스토리</div>
          </div>
        </Link>
        <Link href="/mypage">
          <div
            className={`${styles.menu} ${router.pathname.startsWith('/mypage') ? styles.active : ''} ${styles.mypage}`}>
            {router.pathname.startsWith('/mypage') ? <ActiveMyPageIcon /> : <MyPageIcon />}
            <div className={styles.title}>마이</div>
          </div>
        </Link>
      </Container>
    </div>
  );
}
