import classNames from 'classnames';
import { ReactNode } from 'react';
import styles from '../../../styles/container/container.module.scss';

export type ContainerProps = {
  className?: string;
  children: ReactNode;
};

export const Container = ({ children, className }: ContainerProps) => (
  <div className={classNames(styles.root, className)}>{children}</div>
);
