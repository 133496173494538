import { SearchProduct } from '@/hooks/search/Search.hooks';
import classNames from 'classnames';
import Link from 'next/link';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import styles from '../../../styles/main/recent-view-item-list.module.scss';

type Props = {
  recentItems: SearchProduct[];
};

export default function RecentViewItemList({ recentItems }: Props) {
  return (
    <div className={styles.root}>
      <div className={styles.title}>최근 본 상품</div>
      <Swiper className={styles.swiper} slidesPerView={1.4}>
        {recentItems.map((item, index) => (
          <SwiperSlide key={`recentItem-${item.id}`}>
            <div className={classNames(styles.slideContainer)}>
              <Link
                href={{
                  pathname: `/product/${item.id}`,
                  query: {
                    categoryId: item.categoryId,
                    villageName: item.villageName,
                  },
                }}>
                <div className={styles.itemCard}>
                  <img className={styles.itemImg} src={item.thumbnailUrl} alt={item.name} />
                  <div className={styles.itemContent}>
                    <div className={styles.itemSubtitle}>
                      {item.categoryName} • {item.location}
                    </div>
                    <p className={styles.itemMainTitle}>{item.name}</p>
                  </div>
                </div>
              </Link>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}
