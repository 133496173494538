import Link from 'next/link';
import FoldDownArrow from 'public/assets/images/main/fold-down-arrow.svg';
import WrapUpArrow from 'public/assets/images/main/wrap-up-arrow.svg';
import { useState } from 'react';
import styles from '../../../styles/main/about-us.module.scss';

export default function AboutUs() {
  const [informOpen, setInformOpen] = useState(false);

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <img className={styles.logo} src="/assets/images/main/about-us-logo.png" />
        <div onClick={() => setInformOpen((prev) => !prev)}>
          {informOpen ? <WrapUpArrow /> : <FoldDownArrow />}
        </div>
      </div>
      {informOpen && (
        <div className={styles.information}>
          경기 성남시 분당구 대왕판교로 645번길 12, 9층ㅣ대표이사 하양호ㅣ사업자등록번호
          413-81-09448ㅣ통신판매번호 0000-지역-0000ㅣ호스팅사업자 주식회사 그라운드ㅣ이메일
          groundtour@naver.comㅣ고객센터 010-7278-6050
        </div>
      )}
      <div className={styles.hyperLinks}>
        <Link
          className={styles.hyperLink}
          href={'https://ground.ne.kr/'}
          rel="noopener noreferrer"
          target="_blank">
          회사소개
        </Link>
        <Link
          className={styles.hyperLink}
          href={'https://pf.kakao.com/_aGYxoG/chat'}
          rel="noopener noreferrer"
          target="_blank">
          고객센터
        </Link>
        <Link
          className={styles.hyperLink}
          href={'https://forms.gle/x5dLXFQcrs63GVi88'}
          rel="noopener noreferrer"
          target="_blank">
          입점문의
        </Link>
        <Link
          className={styles.hyperLink}
          href={
            'https://docs.google.com/forms/d/e/1FAIpQLSfCLCISLfONSuJoMwPyg69pkUUDIFtbY6LtDhaV_dzKK1Up4g/viewform'
          }
          rel="noopener noreferrer"
          target="_blank">
          뉴스레터
        </Link>
      </div>
      <div className={styles.terms}>
        <Link
          href={'https://zest-jeep-52c.notion.site/2024-10-01-471ece555c5c44edb873935f16837ecd'}>
          <span>개인정보처리방침</span>
        </Link>
        <span>|</span>
        <Link
          href={
            'https://zest-jeep-52c.notion.site/2024-10-01-eba91a33b8bc476cbaf7038fa2c180db?pvs=4'
          }>
          <span>서비스 이용약관</span>
        </Link>
      </div>
      <div className={styles.notice}>
        (주)그라운드는 통신판매 중개자로서 통신판매의 당사자가 아니며 상품의 예약, 이용 및 환불 등과
        관련란 의무와 책임은 각 판매자에게 있습니다.
      </div>
      <div className={styles.comp}>ⓒ Ground</div>
    </div>
  );
}
