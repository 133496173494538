import { TodayStory } from '@/hooks/main/TodayGroundStory.hooks';
import { formatCurrency } from '@/utils/number';
import Link from 'next/link';
import { Swiper, SwiperSlide } from 'swiper/react';

import classNames from 'classnames';
import 'swiper/css';
import styles from '../../../styles/main/today-ground-story.module.scss';

type Props = {
  todayStories: TodayStory[];
};

export default function TodayGroundStory({ todayStories }: Props) {
  return (
    <div className={styles.root}>
      <div className={styles.title}>오늘의 그라운드 스토리</div>
      <Swiper className={styles.swiper} slidesPerView={1.1}>
        {todayStories.map((story) => (
          <SwiperSlide key={`storyItem-${story.id}`}>
            <div className={classNames(styles.slideContainer)}>
              <Link className={styles.itemSlide} href={`/story/${story.id}`}>
                <img
                  className={styles.itemImg}
                  src={story.thumbnailUrl ? story.thumbnailUrl : story.representativeImageUrl}
                />
                <div className={styles.itemSubTitle}>
                  {story.startDate.replaceAll('-', '.')} • 조회수 {formatCurrency(story.viewCount)}
                </div>
                <div className={styles.itemMainTitle}>{story.title}</div>
              </Link>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}
