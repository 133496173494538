import { Category } from '@/hooks/main/Main.hooks';
import { logout, useMember } from '@/hooks/member/Member.hooks';
import classNames from 'classnames';
import Link from 'next/link';
import CloseIcon from 'public/assets/images/common/close-icon.svg';
import ForwardIcon from 'public/assets/images/common/forward-arrow.svg';
import styles from '../../../styles/main/side-menu.module.scss';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  categories: Category[];
};

export default function SideMenu({ isOpen, onClose, categories }: Props) {
  const { data: { result: member } = {}, mutate } = useMember();

  const handleLogout = async () => {
    await logout();
    await mutate();
  };

  return (
    <div className={classNames(styles.root, { [styles.open]: isOpen })}>
      <div className={classNames(styles.container, { [styles.open]: isOpen })}>
        <div className={styles.header}>
          <button className={styles.closeBtn} onClick={onClose}>
            <CloseIcon />
          </button>
        </div>
        <div className={styles.content}>
          {!member ? (
            <Link href="/login">
              <button className={styles.signinBtn}>
                <h2>로그인 및 회원가입</h2>
                <ForwardIcon stroke="#A8A8A8" />
              </button>
            </Link>
          ) : (
            <div className={styles.loggedIn}>
              <h4 className={styles.userName}>
                <strong>{member?.name}</strong>님
              </h4>
              <button className={styles.logoutBtn} type="button" onClick={handleLogout}>
                로그아웃
              </button>
            </div>
          )}
          <div className={styles.categoryList}>
            {categories.map((cat) => (
              <Link key={cat.id} href={`/category/${cat.id}`}>
                <div className={styles.categoryWrapper}>
                  <div>
                    <div className={styles.iconWrapper}>
                      <img src={cat.iconUrl} />
                    </div>
                    <div className={styles.categoryName}>{cat.name}</div>
                  </div>
                  <ForwardIcon stroke="#A8A8A8" />
                </div>
              </Link>
            ))}
          </div>
          <div className={styles.shortCutList}>
            <Link href="/story">
              <button className={classNames(styles.shortCutBtn, styles.story)}>
                <p className={styles.subTitle}>그라운드의 다양한 소식을 확인해보세요 </p>
                <div className={styles.shortCutTitle}>
                  <span className={styles.mainTitle}>스토리 바로가기</span>
                  <ForwardIcon stroke="#FFFFFF" />
                </div>
              </button>
            </Link>
            <Link href="/nearby/recommend">
              <button className={classNames(styles.shortCutBtn, styles.nearby)}>
                <p className={styles.subTitle}>어디서, 뭐할지 주변 추천 정보를 보여줄게요 </p>
                <div className={styles.shortCutTitle}>
                  <span className={styles.mainTitle}>주변 정보 바로가기</span>
                  <ForwardIcon stroke="#6D5507" />
                </div>
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
