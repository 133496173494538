import { Banner } from '@/hooks/main/Banner.hooks';
import { useState } from 'react';
import { Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import Link from 'next/link';
import 'swiper/css';
import styles from '../../../styles/main/banner-swiper.module.scss';

type Props = {
  banners: Banner[];
};

type GetTargetLink = (linkUrl: string) => [boolean, string];

export default function BannerSwiper({ banners }: Props) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const getTargetLink: GetTargetLink = (linkUrl: string) => {
    if (linkUrl === '') return [false, '#'];
    const baseUrl = process.env.NEXT_PUBLIC_APP_URL as string;
    const originHost = new URL(baseUrl).hostname;
    try {
      const { hostname } = new URL(linkUrl);
      if (originHost === hostname) return [false, linkUrl];
      else return [true, linkUrl];
    } catch (error) {
      return [false, '/'];
    }
  };

  return (
    <div className={styles.root}>
      <Swiper
        className={styles.swiper}
        modules={[Autoplay]}
        slidesPerView={1}
        onSlideChange={(swiper) => setCurrentIndex(swiper.realIndex)}
        loop={true}
        autoplay={{ delay: 3000, disableOnInteraction: false }}>
        {banners
          .sort((a, b) => a.orderNumber - b.orderNumber)
          .map((banner) => {
            const [isOutside, url] = getTargetLink(banner.linkUrl);
            return (
              <SwiperSlide key={banner.id}>
                <Link
                  href={url}
                  rel={isOutside ? 'noopener noreferrer' : undefined}
                  target={isOutside ? '_blank' : undefined}>
                  <div className={styles.bannerImgContainer}>
                    <img src={banner.imageUrl} />
                    <div className={styles.bannerTitleBox}></div>
                  </div>
                </Link>
              </SwiperSlide>
            );
          })}
      </Swiper>
      <div className={styles.indexBadge}>
        <span>{currentIndex + 1}</span>
        <span>/</span>
        <span>{banners.length}</span>
      </div>
    </div>
  );
}
