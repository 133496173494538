import { GroundResponse } from '@/api/axios';
import { Header } from '@/components/layout/Header';
import { Layout } from '@/components/layout/Layout';
import AboutUs from '@/components/main/AboutUs';
import BannerSwiper from '@/components/main/BannerSwiper';
import CategoryList from '@/components/main/CategoryList';
import PopularByRegion from '@/components/main/PopularByRegion';
import PopularTop10 from '@/components/main/PopularTop10';
import RecentViewItemList from '@/components/main/RecentViewItemList';
import SearchBar from '@/components/main/SearchBar';
import SideMenu from '@/components/main/SideMenu';
import ThisWeekRecommandation from '@/components/main/ThisWeekRecommandation';
import TodayGroundStory from '@/components/main/TodayGroundStory';
import { Banner } from '@/hooks/main/Banner.hooks';
import { Category, ProductListItem } from '@/hooks/main/Main.hooks';
import { RandomItem } from '@/hooks/main/ThisWeekRecommandation.hooks';
import { TodayStory } from '@/hooks/main/TodayGroundStory.hooks';
import { useRecentProductList } from '@/hooks/mypage/Recent.hooks';
import { useUnreadNotification } from '@/hooks/notification/Notification.hooks';
import axios from 'axios';
import classNames from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';
import MenuIcon from 'public/assets/images/layout/main/menu-hamburger-icon.svg';
import NotificationIcon from 'public/assets/images/layout/main/notification-icon.svg';
import UnreadNotificationIcon from 'public/assets/images/layout/main/unread-notification-icon.svg';
import UpArrow from 'public/assets/images/main/up-arrow.svg';
import { useEffect, useRef, useState } from 'react';
import mls from '../styles/layout/layout-main.module.scss';
import styles from '../styles/main/main.module.scss';

type Props = {
  categories: Category[];
  banners: Banner[];
  randomItems: RandomItem[];
  topReserves: ProductListItem[];
  topReviews: ProductListItem[];
  topLocations: ProductListItem[];
  todayStories: TodayStory[];
};

export default function Home({
  categories,
  banners,
  randomItems,
  topReserves,
  topReviews,
  topLocations,
  todayStories,
}: Props) {
  const router = useRouter();
  const contentRef = useRef<HTMLDivElement>(null);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const toggleMenu = () => {
    setIsMenuOpen(true);
  };
  const onMenuClose = () => {
    setIsMenuOpen(false);
  };

  const { data: { result: unread = undefined } = {} } = useUnreadNotification();
  const { data: { result: { content: recentItems = [] } = {} } = {} } = useRecentProductList();

  useEffect(() => {
    if (router.query.access_token) {
      window.sessionStorage.setItem('token', router.query.access_token as string);
    }
  }, [router.query.access_token]);

  const handleScroll = () => {
    if (contentRef.current) {
      const { scrollTop } = contentRef.current;
      setScrollPosition(scrollTop);
    }
  };

  const moveToTop = () => {
    contentRef.current?.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <Layout
      className={classNames(mls.root, { [mls.searchHeader]: scrollPosition >= 40 })}
      Header={
        <Header>
          <button className={mls.menuBtn} onClick={toggleMenu}>
            <MenuIcon />
          </button>
          <div className={mls.headerCenter}>
            {scrollPosition < 40 ? (
              <img src="/assets/images/layout/main/ground-logo.png" alt="groud-logo" />
            ) : (
              <SearchBar className={styles.headerSearchBox} />
            )}
          </div>
          <Link className={mls.alarmBtn} href="/notification">
            {unread ? <UnreadNotificationIcon /> : <NotificationIcon />}
          </Link>
        </Header>
      }
      onBodyScroll={handleScroll}
      bodyRef={contentRef}>
      <div>
        <SideMenu isOpen={isMenuOpen} onClose={onMenuClose} categories={categories} />
        <div className={classNames(mls.content, styles.mainContent)}>
          <SearchBar />
          <CategoryList categories={categories} />
          {banners.length > 0 && <BannerSwiper banners={banners} />}
          <PopularTop10 topReserves={topReserves} topReviews={topReviews} />
          {recentItems.length > 0 && <RecentViewItemList recentItems={recentItems} />}
          <PopularByRegion topItems={topLocations} />
          <TodayGroundStory todayStories={todayStories} />
          <ThisWeekRecommandation randomItems={randomItems} />
          <AboutUs />
        </div>
        {scrollPosition >= 50 && (
          <button className={styles.scrollToTop} onClick={moveToTop}>
            <UpArrow />
          </button>
        )}
      </div>
    </Layout>
  );
}

export async function getServerSideProps() {
  const baseUrl = process.env.NEXT_PUBLIC_APP_API;
  const categoriesReq = axios.get<GroundResponse<Category[]>>(`${baseUrl}/app/categories`);
  const bannersReq = axios.get<GroundResponse<Banner[]>>(`${baseUrl}/app/banners`);
  const randomItemsReq = axios.get<GroundResponse<RandomItem[]>>(
    `${baseUrl}/app/products/v2/random-4`
  );
  const topReservesReq = axios.get<GroundResponse<ProductListItem[]>>(
    `${baseUrl}/app/products/v2/reservation/top-10`
  );
  const topReviewsReq = axios.get<GroundResponse<ProductListItem[]>>(
    `${baseUrl}/app/products/v2/review/top-10`
  );
  const topLocationReq = axios.get<GroundResponse<ProductListItem[]>>(
    `${baseUrl}/app/products/v2/location/top-10`,
    {
      params: { location: '서울' },
    }
  );
  const todayStoryReq = axios.get<GroundResponse<TodayStory[]>>(`${baseUrl}/app/stories/today`);

  const [categories, banners, randomItems, topReserves, topReviews, topLocations, todayStories] =
    await Promise.all([
      categoriesReq,
      bannersReq,
      randomItemsReq,
      topReservesReq,
      topReviewsReq,
      topLocationReq,
      todayStoryReq,
    ]);

  return {
    props: {
      categories: categories.data.result,
      banners: banners.data.result,
      randomItems: randomItems.data.result,
      topReserves: topReserves.data.result,
      topReviews: topReviews.data.result,
      topLocations: topLocations.data.result,
      todayStories: todayStories.data.result,
    },
  };
}
