import { Container } from '@/components/container/Container';
import classNames from 'classnames';
import { ReactNode } from 'react';
import styles from '../../../styles/layout/header.module.scss';

export type HeaderProps = {
  className?: string;
  children?: ReactNode;
};

export const Header = ({ className, children }: HeaderProps) => {
  return <Container className={classNames(styles.root, className)}>{children}</Container>;
};
