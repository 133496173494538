import { ProductListItem } from '@/hooks/main/Main.hooks';
import { useFavoriteLocationList, useLocationTopProducts } from '@/hooks/main/TopLocation.hook';
import { formatCurrency } from '@/utils/number';
import classNames from 'classnames';
import Link from 'next/link';
import { useEffect, useState } from 'react';
import SwiperCore from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import FloatingBadgeList from './FloatingBadgeList';

import 'swiper/css';
import styles from '../../../styles/main/popular-by-region.module.scss';

type Props = {
  topItems: ProductListItem[];
};

export default function PopularByRegion({}: Props) {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [swiper, setSwiper] = useState<SwiperCore>();

  const { data: { result: regions = [] } = {} } = useFavoriteLocationList();

  const location = regions.length === 0 ? undefined : regions[selectedIndex].location;
  const { data: { result: topProducts = [] } = {} } = useLocationTopProducts(location);

  useEffect(() => {
    if (swiper) {
      swiper.slideTo(0, 0);
    }
  }, [topProducts, swiper]);

  return (
    <div className={styles.root}>
      <div className={styles.title}>지역별 인기</div>
      <FloatingBadgeList
        className={styles.floatingBadges}
        list={regions.map((item) => ({ label: item.location, value: item.location }))}
        setSelectedIndex={setSelectedIndex}
      />
      <Swiper className={styles.swiper} onSwiper={setSwiper} slidesPerView={1.4}>
        {topProducts.map((item, index) => {
          const isAcco = item.productType === '숙소예약';
          return (
            <SwiperSlide key={`popularRegion-${item.id}`}>
              <div className={classNames(styles.slideContainer)}>
                <Link
                  key={`topLoc-${item.id}`}
                  className={styles.itemSlide}
                  href={{
                    pathname: `/product/${item.id}`,
                    query: {
                      categoryId: item.categoryId,
                      villageName: item.villageName,
                    },
                  }}>
                  <div className={styles.itemImgWrapper}>
                    <img src={item.thumbnailUrl} />
                    <div className={styles.itemNumber}>{index + 1}</div>
                  </div>
                  <div className={styles.itemContent}>
                    <div className={styles.itemSubTitle}>
                      {item.categoryName} • {item.location}
                    </div>
                    <p className={styles.itemMainTitle}>{item.name}</p>
                    <div className={styles.itemPriceWrapper}>
                      <div className={styles.currentPrice}>
                        {formatCurrency(item.price)}원{isAcco && '~'}
                      </div>
                      {!isAcco && item.originPrice > 0 && (
                        <div className={styles.originPrice}>
                          {formatCurrency(item.originPrice)}원
                        </div>
                      )}
                    </div>
                  </div>
                </Link>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
}
