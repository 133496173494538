import { GroundResponse } from '@/api/axios';
import axios from 'axios';
import useSWR from 'swr';
import { ProductListItem } from './Main.hooks';

export type FavoriteLocation = {
  location: string;
  count: number;
};

type Params = [url: string, location?: string];

const fetcher = async <T>([url, location]: Params) => {
  const { data } = await axios.get<GroundResponse<T>>(
    url,
    !!location
      ? {
          params: { location },
        }
      : undefined
  );
  return data;
};

export const useFavoriteLocationList = () => {
  const swr = useSWR<GroundResponse<FavoriteLocation[]>>(
    [`${process.env.NEXT_PUBLIC_APP_API}/app/products/v2/location/favorite`],
    fetcher,
    {}
  );
  return swr;
};

export const useLocationTopProducts = (location: string | undefined) => {
  const swr = useSWR<GroundResponse<ProductListItem[]>>(
    !!location
      ? [`${process.env.NEXT_PUBLIC_APP_API}/app/products/v2/location/top-10`, location]
      : null,
    fetcher,
    {}
  );
  return swr;
};
